import { useState } from 'react';
import Dropdown from './Dropdown';

const ListTransportationOffers = ({ data }) => {
    const [offers, setOffers] = useState(data);

    const handleChangeStatus = async (offerId, newStatus) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}transportation-offers/${offerId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }),
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`Failed to update offer status: ${response.status} ${response.statusText}`);
            }

            const updatedOffers = offers.map(offer =>
                offer._id === offerId ? { ...offer, status: newStatus } : offer
            );
            setOffers(updatedOffers);
            console.log(`Offer ${offerId} status updated to: ${newStatus}`);
        } catch (error) {
            console.error('Error updating offer status:', error.message);
        }
    };
    return (
        <table className='w-full'>
            <thead className='bg-[#F1F4F9]'>
                <tr>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Company name</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>First name</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Last name</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Email</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Phone number</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Shipping type</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Shipping mode</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Status</th>
                    <th className='py-3.5 px-2.5 border'>Created At</th>
                </tr>
            </thead>
            <tbody>
                {offers.map((offer) => (
                    <tr key={offer._id} className='bg-white'>
                        <td className='py-3.5 px-2.5 border'>{offer.companyName}</td>
                        <td className='py-3.5 px-2.5 border'>{offer.firstName}</td>
                        <td className='py-3.5 px-2.5 border'>{offer.lastName}</td>
                        <td className='py-3.5 px-2.5 border'>{offer.email}</td>
                        <td className='py-3.5 px-2.5 border'>{offer.phoneNumber}</td>
                        <td className='py-3.5 px-2.5 border'>{offer.shippingType}</td>
                        <td className='py-3.5 px-2.5 border uppercase'>{offer.shippingMode}</td>
                        <td className='py-3.5 px-2.5 border flex justify-center'>
                            <Dropdown
                                status={offer.status}
                                setStatus={(newStatus) => handleChangeStatus(offer._id, newStatus)}
                            />
                        </td>
                        <td className='py-3.5 px-2.5 border'>{new Date(offer.createdAt).toDateString()}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ListTransportationOffers;