import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'universal-cookie';
import Loader from '../components/ui/Loader';

const Authentication = () => {
    const cookies = new Cookies();
    const redirect = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}auth/sing-in`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 200) {
                const data = response.data;
                if (data && data.token) {
                    cookies.set("token", data.token)
                    cookies.set('userId', data.user.id);
                    cookies.set('isAdmin', data.user.role === "superadmin" ? true : false);

                    console.log('Authentication successful');
                    redirect("/");
                } else {
                    throw new Error('Authentication failed: Invalid response from server');
                }
            } else {
                throw new Error(`Failed to authenticate: ${response.status} ${response.statusText}`);
            }

        } catch (error) {
            console.error('Error:', error);
            setError("Something went wrong during login. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loader />;

    return (
        <section>
            <div className="container mx-auto">
                <div className="h-screen flex items-center justify-center">
                    <div className='relative'>
                        <div className="absolute inset-0 bg-gradient-to-r from-primary to-primary-dark shadow-xl transform skew-y-0 -rotate-6 rounded-xl md:rounded-3xl"></div>
                        <div className="relative bg-background shadow-md rounded-xl md:rounded-3xl p-[10vw] sm:p-16 md:p-20">
                            <form onSubmit={handleSubmit} className="w-[45vw] sm:w-72 md:w-80">
                                <h1 className="text-copy font-bold text-[5vw] sm:text-3xl md:text-4xl leading-none  sm:leading-tight md:leading-normal tracking-wide drop-shadow-md">Staff Page</h1>

                                <div className="py-[5vw] sm:py-10 space-y-[3vw] sm:space-y-6">
                                    <div className="relative">
                                        <input autoComplete="off" id="username" name="username" value={formData.username} onChange={handleInputChange} disabled={loading} type="text" className="peer placeholder-transparent h-[8vw] sm:h-12 md:h-14 w-full border-b-2 border-border text-copy font-black text-[3.5vw] sm:text-xl md:text-2xl disabled:font-normal disabled:text-copy-light disabled:bg-transparent focus:outline-none focus:border-primary" placeholder="Please enter username..." />
                                        <label htmlFor="username" className="absolute left-0 -top-[2.5vw] sm:-top-3.5 text-copy-dark text-[2.5vw] sm:text-base peer-placeholder-shown:text-[2.5vw] sm:peer-placeholder-shown:text-base peer-placeholder-shown:text-copy peer-placeholder-shown:top-[2vw] sm:peer-placeholder-shown:top-4 transition-all peer-focus:-top-[2.5vw] sm:peer-focus:-top-3.5 peer-focus:text-primary peer-focus:text-[2.5vw] sm:peer-focus:text-base">Username</label>
                                    </div>
                                    <div className="relative">
                                        <input autoComplete="off" id="password" name="password" value={formData.password} onChange={handleInputChange} disabled={loading} type="password" className="peer placeholder-transparent h-[8vw] sm:h-12 md:h-14 w-full border-b-2 border-border text-copy font-black text-[3.5vw] sm:text-xl md:text-2xl disabled:font-normal disabled:text-copy-light disabled:bg-transparent focus:outline-none focus:border-primary" placeholder="Please enter your password.." />
                                        <label htmlFor="password" className="absolute left-0 -top-[2.5vw] sm:-top-3.5 text-copy-dark text-[2.5vw] sm:text-base peer-placeholder-shown:text-[2.5vw] sm:peer-placeholder-shown:text-base peer-placeholder-shown:text-copy peer-placeholder-shown:top-[2vw] sm:peer-placeholder-shown:top-4 transition-all peer-focus:-top-[2.5vw] sm:peer-focus:-top-3.5 peer-focus:text-primary peer-focus:text-[2.5vw] sm:peer-focus:text-base">Password</label>
                                    </div>
                                    <div className="flex-center">
                                        <button type="submit" disabled={loading} className="w-fit py-[2vw] sm:py-3.5 px-[5vw] sm:px-8 rounded-[1.5vw] sm:rounded-xl bg-primary hover:bg-background text-white hover:text-primary text-[2vw] sm:text-sm md:text-xl font-black drop-shadow-md tracking-tight leading-normal flex-center shadow-inner hover:shadow-md border-[0.5px] border-border hover:border-primary transition-all duration-[0.3s] ease-in-out">Submit</button>
                                    </div>
                                </div>

                                <div className="flex-center text-center mb-2.5">
                                    {
                                        !error && <span className="text-copy-dark font-normal text-[2vw] sm:text-[10px] md:text-xs drop-shadow-md leading-snug">This web page for only admins <a href="/terms" className="text-secondary">read more</a></span>
                                    }
                                    {
                                        error && <span className="text-error font-bold text-[2vw] sm:text-[10px] md:text-xs drop-shadow-md leading-snug">{error}</span>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Authentication;