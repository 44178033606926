import { useState } from 'react';
import { getStatusClassName } from '../../utils/helper';

const Dropdown = ({ status, setStatus }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(prevState => !prevState);
    };

    const handleThemeSelection = (status) => {
        setStatus(status);
        setIsOpen(false);
    };

    const statusOptions = [
        { key: 'active', label: 'Active' },
        { key: 'in-progress', label: 'In progress' },
        { key: 'rejected', label: 'Rejected' },
        { key: 'accepted', label: 'Accepted' },
    ];

    return (
        <div className="relative">
            {/* Dropdown button */}
            <button
                onClick={toggleDropdown}
                className={`py-2.5 px-5 w-40 rounded-md text-white font-bold cursor-pointer ${getStatusClassName(status)}`}
            >
                {status}
            </button>

            {/* Dropdown menu */}
            {isOpen && (
                <div className="absolute mt-2 p-2 w-full z-10 rounded-md bg-primary" >
                    {statusOptions.map(option => (
                        option.key !== status && (
                            <button
                                key={option.key}
                                onClick={() => handleThemeSelection(option.key)}
                                className={`py-2.5 px-5 my-1.5 w-full rounded-md text-copy-light font-bold ${getStatusClassName(option.key)}`} >
                                {option.label}
                            </button>
                        )
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;