import Analitics from "./components/ui/Analitics";
import Chart from "./components/ui/Chart";

function App() {
  return (

    <main className="p-2.5">
      <Analitics />
      <Chart />
    </main>
  );
}

export default App;
