const ListComments = ({ comments, success, handleDeleteComment }) => {

    return (
        <table className='w-full'>
            <thead className='bg-[#F1F4F9]'>
                <tr>
                    <th className='py-3.5 px-2.5 border'>First name</th>
                    <th className='py-3.5 px-2.5 border'>Last name</th>
                    <th className='py-3.5 px-2.5 border'>Company name</th>
                    <th className='py-3.5 px-2.5 border'>Company logo</th>
                    <th className='py-3.5 px-2.5 border'>Created At</th>
                    <th className='py-3.5 px-2.5 border'>Actions</th>
                </tr>
            </thead>
            <tbody>
                {comments.map((comment) => (
                    <tr key={comment._id} className='bg-white'>
                        <td className='py-3.5 px-2.5 border'>{comment.firstName}</td>
                        <td className='py-3.5 px-2.5 border'>{comment.lastName}</td>
                        <td className='py-3.5 px-2.5 border'>{comment.companyName}</td>
                        <td className='py-3.5 px-2.5 border'>
                            <img src={comment.companyLogo?.img} alt={comment.companyName} className='w-10 h-10 object-cover border border-primary rounded-md' />
                        </td>
                        <td className='py-3.5 px-2.5 border'>{new Date(comment.createdAt).toDateString()}</td>
                        <td className='py-3.5 px-2.5 border flex justify-center'>
                            <button onClick={() => handleDeleteComment(comment._id)} type="submit" className="py-2 px-3 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 dark:focus:ring-red-900">
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default ListComments