import { useEffect, useState } from 'react'

import ListComments from '../components/ui/ListComments';
import Loader from '../components/ui/Loader';
import axios from 'axios';

const ListOfComment = () => {

    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState(null);
    const [success, setSuccess] = useState(null);

    const fetchComments = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}comments`);

            if (response.status !== 200) {
                throw new Error(`Failed to fetch comments: ${response.status} ${response.statusText}`);
            }

            if (response.status === 200) {
                const data = response.data;
                setComments(data.data)
                setSuccess("Comment fetched successfully !")
            }

        } catch (error) {
            setLoading(false);
            console.error('Error updating offer status:', error.message);
        }
    };

    useEffect(() => {
        fetchComments()
    }, [])

    const handleDeleteComment = async (offerId) => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}comments/${offerId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`Failed to delete comments: ${response.status} ${response.statusText}`);
            }

            if (response.ok) {
                setSuccess("Comment deleted successfully !")
                fetchComments()
                setLoading(false);
            }

        } catch (error) {
            setLoading(false);
            console.error('Error updating offer status:', error.message);
        }
    };

    if (loading) return <Loader />;

    return (
        <section className='list-of-cars__section'>
            <div className="container mx-auto">

                <div className='flex justify-between items-center mb-10'>
                    <h3 className="text-copy-dark text-3xl font-black leading-normal">List of Comments</h3>
                </div>

                <div className="">
                    {
                        !comments
                            ? <h1>Loading.....</h1>
                            : <ListComments comments={comments} success={success} handleDeleteComment={handleDeleteComment} />
                    }
                </div>
            </div>
        </section>
    )
}

export default ListOfComment