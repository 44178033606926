import Cookies from "universal-cookie";
const cookies = new Cookies();

export const isLoggedIn = () => {
  const token = cookies.get("token");
  return !!token;
};

export const getStatusClassName = (status) => {
  switch (status) {
    case "active":
      return "bg-success";
    case "accepted":
      return "bg-info";
    case "rejected":
      return "bg-error";
    case "in-progress":
      return "bg-warning";
    default:
      return "bg-primary";
  }
};
