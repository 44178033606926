import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ListAdmins = ({ data, handleDeleteAdmin, allowed }) => {
    const redirect = useNavigate()
    const [admins, setAdmins] = useState(data);

    const handleChangePermission = async (adminId, permissionKey,) => {
        try {
            const updatedAdmins = admins.map((admin) =>
                admin._id === adminId
                    ? {
                        ...admin,
                        permissions: {
                            ...admin.permissions,
                            [permissionKey]: !admin.permissions[permissionKey],
                        },
                    }
                    : admin
            );
            setAdmins(updatedAdmins);

            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}users/${adminId}/permissions`,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        permissions: updatedAdmins.find(
                            (admin) => admin._id === adminId
                        ).permissions,
                    }),
                    credentials: "include",
                }
            );

            if (!response.ok) {
                throw new Error(
                    `Failed to update admin permissions: ${response.status} ${response.statusText}`
                );
            }

            console.log(`Admin ${adminId} permissions updated.`);
        } catch (error) {
            console.error("Error updating admin permissions:", error.message);
        }
    };

    const handleEditAdmin = (adminId) => {
        redirect(`/edit-admin/${adminId}`);
    };





    return (
        <table className='w-full'>
            <thead className='bg-[#F1F4F9]'>
                <tr>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Username</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Email</th>
                    <th className='col-span-1 py-3.5 px-2.5 border'>Role</th>
                    {
                        allowed && <th className='col-span-1 py-3.5 px-2.5 border'>Permissions</th>
                    }
                    {
                        allowed && <th className='col-span-1 py-3.5 px-2.5 border'>Actions</th>
                    }
                </tr>
            </thead>
            <tbody>
                {admins.map((admin) => (
                    <tr key={admin._id} className='bg-white'>
                        <td className='py-3.5 px-2.5 border'>{admin.username}</td>
                        <td className='py-3.5 px-2.5 border'>{admin.email}</td>
                        <td className='py-3.5 px-2.5 border'>{admin.role}</td>
                        {
                            allowed &&
                            <td className='py-3.5 px-2.5 border'>
                                <ul className="flex gap-5">
                                    {Object.keys(admin.permissions).map((permission) => (
                                        <li key={permission} className="flex items-center justify-between gap-2">
                                            <span className="py-2.5 px-2.5 border-r capitalize">
                                                {permission}
                                            </span>
                                            {admin.permissions[permission] ? (
                                                <span
                                                    onClick={() => handleChangePermission(admin._id, permission)}
                                                    className="py-2.5 px-2.5 my-1.5 rounded-md text-copy-light text-sm bg-success cursor-pointer"
                                                >
                                                    Enabled
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() => handleChangePermission(admin._id, permission)}
                                                    className="py-2.5 px-2.5 my-1.5 rounded-md text-copy-light text-sm bg-error cursor-pointer"
                                                >
                                                    Disabled
                                                </span>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        }
                        {
                            allowed && (
                                <td className='py-3.5 px-2.5 border flex items-center justify-center gap-2.5'>
                                    <button onClick={() => handleEditAdmin(admin._id)} className="min-w-20 py-2.5 px-2.5 my-1.5 rounded-md text-copy-light text-sm bg-info">Edit</button>
                                    <button onClick={() => handleDeleteAdmin(admin._id)} className="min-w-20 py-2.5 px-2.5 my-1.5 rounded-md text-copy-light text-sm bg-error">Delete</button>
                                </td>
                            )
                        }

                    </tr>
                ))}

            </tbody>
        </table>
    )
}

export default ListAdmins;