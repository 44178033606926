import { Routes, Route } from 'react-router-dom';

import App from '../App';
import Authentication from '../pages/Authentication';
import Layout from '../components/sections/Layout';
import ListOfTransportationOffers from '../pages/ListOfTransportationOffers';
import ListOfOffers from '../pages/ListOfOffers';
import ListOfAdmins from '../pages/ListOfAdmins';
import PrivateRoute from './PrivateRoute';
import AddAdmin from '../pages/AddAdmin';
import Profile from '../pages/Profile';
import EditAdmin from '../pages/EditAdmin';
import ListOfComment from '../pages/ListOfComment';


const RooteRoute = () => {
    return (
        <Routes>
            <Route path="/login" element={<Authentication />} />
            <Route path="/" element={<Layout />}>
                <Route index element={<PrivateRoute><App /></PrivateRoute>} />
                <Route path='/list-of-offers' element={<PrivateRoute><ListOfOffers /></PrivateRoute>} />
                <Route path='/list-of-transportation-offers' element={<PrivateRoute><ListOfTransportationOffers /></PrivateRoute>} />
                <Route path='/list-of-admins' element={<PrivateRoute><ListOfAdmins /></PrivateRoute>} />
                <Route path='/list-of-comments' element={<PrivateRoute><ListOfComment /></PrivateRoute>} />
                <Route path='/add-admin' element={<PrivateRoute><AddAdmin /></PrivateRoute>} />
                <Route path='/edit-admin/:id' element={<PrivateRoute><EditAdmin /></PrivateRoute>} />
                <Route path='/profile/:id' element={<PrivateRoute><Profile /></PrivateRoute>} />
            </Route>
        </Routes>
    )
}


export default RooteRoute