import { useEffect, useState } from "react";

import { LineChart } from '@mui/x-charts/LineChart';

import Loader from "./Loader";

const Chart = () => {
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


    useEffect(() => {
        const fetchOffers = async (url) => {
            try {
                setLoading(true);
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch offers: ${response.status} ${response.statusText}`);
                }

                const { data } = await response.json();
                setLoading(false);

                return data;
            } catch (error) {
                setLoading(false);
                console.error('Error fetching offers:', error.message);
                return [];
            }
        };

        const fetchAllOffers = async () => {
            const transportationOffers = await fetchOffers(`${process.env.REACT_APP_BACKEND_URL}transportation-offers`);
            const otherOffers = await fetchOffers(`${process.env.REACT_APP_BACKEND_URL}offers`);
            setOffers([...transportationOffers, ...otherOffers]);
        };

        fetchAllOffers();
    }, []);

    if (loading) return <Loader />;

    const calculateOfferStats = (offers) => {

        const monthCounts = {
            "01": { accepted: 0, rejected: 0 },
            "02": { accepted: 0, rejected: 0 },
            "03": { accepted: 0, rejected: 0 },
            "04": { accepted: 0, rejected: 0 },
            "05": { accepted: 0, rejected: 0 },
            "06": { accepted: 0, rejected: 0 },
            "07": { accepted: 0, rejected: 0 },
            "08": { accepted: 0, rejected: 0 },
            "09": { accepted: 0, rejected: 0 },
            "10": { accepted: 0, rejected: 0 },
            "11": { accepted: 0, rejected: 0 },
            "12": { accepted: 0, rejected: 0 }
        };

        offers.forEach(offer => {
            const month = new Date(offer.createdAt).getMonth() - 3;
            const monthKey = month.toString().padStart(2, '0');
            
            if (offer.status === "accepted") {
                monthCounts[monthKey].accepted++;
            } else if (offer.status === "rejected") {
                monthCounts[monthKey].rejected++;
            }
        });
        const months = Object.keys(monthCounts);
        const acceptedData = months.map(month => monthCounts[month].accepted);
        const rejectedData = months.map(month => monthCounts[month].rejected);

        return { months, acceptedData, rejectedData };
    };

    const { acceptedData, rejectedData } = calculateOfferStats(offers);

    return (
        <div className='py-10'>
            <div className="w-full h-[560px]">
                <LineChart
                    series={[
                        { data: acceptedData, label: 'Accepted' },
                        { data: rejectedData, label: 'Rejected' },
                    ]}
                    xAxis={[{ scaleType: 'point', data: months }]}
                    className="w-full h-96"
                />
            </div>
        </div>
    );
}

export default Chart;
