import Sidebar from '../ui/Sidebar'
import Header from './Header'
import { Outlet } from 'react-router-dom';

const Layout = () => {
    return (
        <div className='flex'>
            <Sidebar />
            <section className="flex-1 bg-background rounded-l-4xl shadow-xl">
                <Header />
                <div className="p-2.5 xl:p-5">
                    <Outlet />
                </div>
            </section>
        </div>
    )
}

export default Layout