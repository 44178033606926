import { useState } from "react"
import { Dashboard, Plane, } from "../../icons/icons"

const Sidebar = () => {
    const [open, setOpen] = useState(false)

    return (
        <div className={`${open ? "w-72" : "w-22"} sticky top-0 z-10 hidden md:block h-screen py-2.5 px-3.5 bg-foreground overflow-hidden`}>

            {/* Sidebar Header */}
            <div className={`flex w-full ${open ? "justify-end" : "justify-center"} py-5`}>
                <div className={`p-2.5 bg-primary rounded-xl border border-border cursor-pointer ${open ? "rotate-180" : "rotate-0"}`} onClick={() => setOpen(!open)}>
                    <Plane className="w-5 h-5 stroke-white fill-primary" />
                </div>
            </div>

            {/* Sidebar Navigation */}
            <ul className='flex flex-col justify-start items-start gap-y-2.5 mt-3'>
                <a href="/" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-small p-1.5">
                    <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                        <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                            <Dashboard className="w-5 h-5 stroke-white fill-primary" />
                        </div>
                        <span className={`${open ? "block" : "hidden"} text-copy text-lg font-bold tracking-tight leading-normal`}>Dashboard</span>
                    </li>
                </a>
                <a href="/list-of-offers" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-small p-1.5">
                    <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                        <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                            <Dashboard className="w-5 h-5 stroke-white fill-primary" />
                        </div>
                        <span className={`${open ? "block" : "hidden"} text-copy text-lg font-bold tracking-tight leading-normal`}>List of offers</span>
                    </li>
                </a>
                <a href="/list-of-transportation-offers" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-small p-1.5">
                    <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                        <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                            <Dashboard className="w-5 h-5 stroke-white fill-primary" />
                        </div>
                        <span className={`${open ? "block" : "hidden"} text-copy text-lg font-bold tracking-tight leading-normal`}>List of transportation offers</span>
                    </li>
                </a>
                <a href="/list-of-admins" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-small p-1.5">
                    <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                        <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                            <Dashboard className="w-5 h-5 stroke-white fill-primary" />
                        </div>
                        <span className={`${open ? "block" : "hidden"} text-copy text-lg font-bold tracking-tight leading-normal`}>List of admins</span>
                    </li>
                </a>
                <a href="/list-of-comments" className="w-full flex justify-start border border-transparent rounded-xl hover:border-primary hover:shadow-small p-1.5">
                    <li className='flex items-center justify-center gap-2.5 cursor-pointer'>
                        <div className='bg-primary dark:bg-primary-dark p-2.5 rounded-xl border border-border'>
                            <Dashboard className="w-5 h-5 stroke-white fill-primary" />
                        </div>
                        <span className={`${open ? "block" : "hidden"} text-copy text-lg font-bold tracking-tight leading-normal`}>List of comments</span>
                    </li>
                </a>
            </ul>
        </div>
    )
}
export default Sidebar;