import { useEffect, useState } from "react";
import { AcceptedIcon, ActiveIcon, InProgressIcon, RejectedIcon } from "../../icons/icons";
import Loader from "./Loader";

const Analitics = () => {
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState([]);
    const [transportationOffers, setTransportationOffers] = useState([]);
    const [status, setStatus] = useState({
        active: 0,
        inProgress: 0,
        rejected: 0,
        accepted: 0
    })

    useEffect(() => {

        const fetchOffers = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}transportation-offers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch offers: ${response.status} ${response.statusText}`);
                }

                const { data } = await response.json();
                setLoading(false);

                setTransportationOffers(data);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching offers:', error.message);
            }
        };

        fetchOffers()

    }, [])

    useEffect(() => {

        const fetchOffers = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}offers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch offers: ${response.status} ${response.statusText}`);
                }

                const { data } = await response.json();
                setLoading(false);
                setOffers(data);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching offers:', error.message);
            }
        };

        fetchOffers()

    }, [])

    useEffect(() => {
        const fetchedOffers = [...offers, ...transportationOffers];

        const updatedStatus = fetchedOffers.reduce((acc, offer) => {
            if (offer.status === 'active') {
                acc.active++;
            } else if (offer.status === 'in-progress') {
                acc.inProgress++;
            } else if (offer.status === 'rejected') {
                acc.rejected++;
            } else if (offer.status === 'accepted') {
                acc.accepted++;
            }
            return acc;
        }, { active: 0, inProgress: 0, rejected: 0, accepted: 0 });

        if (
            updatedStatus.active !== status.active ||
            updatedStatus.inProgress !== status.inProgress ||
            updatedStatus.rejected !== status.rejected ||
            updatedStatus.accepted !== status.accepted
        ) {
            setStatus(updatedStatus);
        }
    }, [offers, status, transportationOffers]);



    if (loading) return <Loader />;

    return (
        <div className='grid grid-flow-row grid-cols-4 gap-5'>
            <div className='p-5 flex-center gap-5 border border-border rounded-3xl'>
                <div className='relative w-20 h-20 p-5 border border-border rounded-full overflow-hidden'>
                    <ActiveIcon className="w-10 h-10 stroke-primary fill-primary" />
                </div>
                <div className='flex flex-col'>
                    <h4 className='text-success text-xl font-bold leading-normal'>Active</h4>
                    <span className='text-copy-dark text-3xl font-black leading-tight'>{status.active}</span>
                </div>
            </div>
            <div className='p-5 flex-center gap-5 border border-border rounded-3xl'>
                <div className='relative w-20 h-20 p-5 border border-border rounded-full overflow-hidden'>
                    <InProgressIcon className="w-10 h-10 stroke-primary fill-primary" />
                </div>
                <div className='flex flex-col'>
                    <h4 className='text-warning text-xl font-bold leading-normal'>In progress</h4>
                    <span className='text-copy-dark text-3xl font-black leading-tight'>{status.inProgress}</span>
                </div>
            </div>
            <div className='p-5 flex-center gap-5 border border-border rounded-3xl'>
                <div className='relative w-20 h-20 p-5 border border-border rounded-full overflow-hidden'>
                    <RejectedIcon className="w-10 h-10 stroke-primary fill-primary" />
                </div>
                <div className='flex flex-col'>
                    <h4 className='text-error text-xl font-bold leading-normal'>Rejected</h4>
                    <span className='text-copy-dark text-3xl font-black leading-tight'>{status.rejected}</span>
                </div>
            </div>
            <div className='p-5 flex-center gap-5 border border-border rounded-3xl'>
                <div className='relative w-20 h-20 p-5 border border-border rounded-full overflow-hidden'>
                    <AcceptedIcon className="w-10 h-10 stroke-primary" />
                </div>
                <div className='flex flex-col'>
                    <h4 className='text-info text-xl font-bold leading-normal'>Accepted</h4>
                    <span className='text-copy-dark text-3xl font-black leading-tight'>{status.accepted}</span>
                </div>
            </div>
        </div>
    )
}

export default Analitics