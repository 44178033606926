import { useEffect, useState } from "react"
import { LogOut, Notification, Settings, User } from "../../icons/icons";
import { useLocation } from "react-router-dom";

import Cookies from 'universal-cookie';

const Header = () => {
    const cookies = new Cookies();

    const location = useLocation();
    const [page, setPage] = useState(null);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        if (location.pathname) {
            setPage(location.pathname.substr(1))
        }
    }, [location.pathname])

    useEffect(() => {
        const userId = cookies.get("userId");

        if (userId) {
            setUserId(userId)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleLogOut = () => {
        cookies.remove("token");
        cookies.remove("userId");
        window.location.reload();
    }


    return (
        <header className="sticky top-0 z-10 overflow-hidden bg-background shadow-sm">
            {/* Navbar */}
            <nav className='w-full flex justify-between items-center px-10'>

                {/* Navbar Left Side / Page shower  */}
                {
                    location.pathname &&
                    <div className='text-copy-lighter text-[3vw] sm:text-sm font-bold dark:font-normal leading-tight drop-shadow-xl'>
                        page / <span className="text-copy text-[3.5vw] sm:text-sm dark:font-bold drop-shadow-xl">{page === "" ? "home" : page}</span>
                    </div>
                }

                {/* Navbar Right Side / Controller */}
                <div className='flex items-center gap-[2vw] sm:gap-5 py-[1.1vw] sm:py-5'>
                    <div className="flex items-center gap-1.5 sm:gap-3.5">
                        <div className='p-1.5 rounded-full border border-border cursor-pointer'><Notification className="w-[3vw] sm:w-5 h-[3vw] sm:h-5 fill-error" /></div>
                        <div className='p-1.5 rounded-full border border-border cursor-pointer'><Settings className="w-[3vw] sm:w-5 h-[3vw] sm:h-5 fill-info" /></div>
                    </div>
                    {
                        userId && (
                            <a href={`/profile/${userId}`} className="p-1 rounded-full border border-primary">
                                <User className="w-8 h-8 stroke-primary fill-primary" />
                            </a>
                        )
                    }
                    {
                        userId && (
                            <button className='p-2.5 rounded-full border border-primary' onClick={handleLogOut}>
                                <LogOut className="w-5 h-5 stroke-primary" />
                            </button>
                        )
                    }

                </div>

            </nav>
        </header>
    )
}

export default Header;