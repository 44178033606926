import { useEffect, useState } from "react";

import Dropdown from "../components/ui/Dropdown";
import ListTransportationOffers from "../components/ui/ListTransportationOffers";
import Loader from "../components/ui/Loader";

const ListOfTransportationOffers = () => {
    const [loading, setLoading] = useState(false);
    const [offers, setOffers] = useState(null);
    const [status, setStatus] = useState("active");

    useEffect(() => {

        const fetchOffers = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}transportation-offers`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(`Failed to fetch offers: ${response.status} ${response.statusText}`);
                }

                const { data } = await response.json();
                setLoading(false);

                setOffers(data);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching offers:', error.message);
            }
        };

        fetchOffers()

    }, [])

    if (loading) return <Loader />;
    
    return (
        <section className='list-of-cars__section'>
            <div className="container mx-auto">

                <div className='flex justify-between items-center mb-10'>
                    <h3 className="text-copy-dark text-3xl font-black leading-normal">List Of Transportation Offers</h3>

                    <Dropdown status={status} setStatus={setStatus} />
                </div>

                <div className="">
                    {
                        !offers
                            ? <h1>Loading.....</h1>
                            : <ListTransportationOffers data={offers} />
                    }
                </div>
            </div>
        </section>
    )
}

export default ListOfTransportationOffers;