import { useEffect, useState } from 'react'

import ListAdmins from '../components/ui/ListAdmins';
import Loader from '../components/ui/Loader';
import Cookies from 'universal-cookie';

const ListOfAdmins = () => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [admins, setAdmins] = useState(null);
  const [allowed, setAllowed] = useState(null)

  const fetchAdmins = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch offers: ${response.status} ${response.statusText}`);
      }

      if (response.ok) {
        setLoading(false);
        const { data } = await response.json();
        setAdmins(data);
      }

    } catch (error) {
      setLoading(false);
      console.error('Error fetching offers:', error.message);
    }
  };

  useEffect(() => {
    fetchAdmins()
  }, [])

  useEffect(() => {
    const isAdmin = cookies.get("isAdmin");

    if (isAdmin) {
      setAllowed(isAdmin)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDeleteAdmin = async (adminId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}users/${adminId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`Failed to delete admin: ${response.status} ${response.statusText}`);
      }

      const updatedAdmins = admins.filter(admin => admin._id !== adminId);
      setAdmins(updatedAdmins);

      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.error('Error deleting admin:', error);
      alert("Something went wrong during admin deletion. Please try again.");
    }
  };


  if (loading) return <Loader />;

  return (
    <section className='list-of-cars__section'>
      <div className="container mx-auto">

        <div className='flex justify-between items-center mb-10'>
          <h3 className="text-copy-dark text-3xl font-black leading-normal">List Of Admins</h3>
          {
            allowed &&
            <button className="py-2.5 px-5 w-40 bg-primary rounded-md text-white font-bold cursor-pointer">
              <a href='add-admin'>
                Add admin
              </a>
            </button>
          }
        </div>

        <div className="">
          {
            !admins
              ? <h1>Loading.....</h1>
              : <ListAdmins data={admins} handleDeleteAdmin={handleDeleteAdmin} allowed={allowed} />
          }
        </div>
      </div>
    </section>
  )
}

export default ListOfAdmins